import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
//add this line to the top of your file
import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import App from './App';
import TagManager from 'react-gtm-module'
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from '@redux-saga/core';
import store from './redux/store';
// import { myReducer } from '../reducers';

// const tagManagerArgs = {
//   gtmId: 'GTM-KSQG9LH'
// }

// TagManager.initialize(tagManagerArgs)
// ReactDOM.render(<App />, document.getElementById('root'));

// const store = configureStore({ reducer: myReducer });
console.warn(store)
ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
       {/*  <PersistGate loading={null} persistor={persistor}> */}
          <App />
        {/* </PersistGate>*/}
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
