export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const EMPTY_CART = "EMPTY_CART"
export const PRODUCT_LIST = "PRODUCT_LIST"
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST"
export const SEARCH_PRODUCT = "SEARCH_PRODUCT"

export const SET_USER_DATA = "SET_USER_DATA"
export const GET_USER_DATA = "GET_USER_DATA"

export const LOGIN_ADMIN_USER = "LOGIN_ADMIN_USER"
export const UPDATE_LOGIN_ADMIN_USER = "UPDATE_LOGIN_ADMIN_USER"
export const SET_LOGIN_ADMIN_USER_DATA = "SET_LOGIN_ADMIN_USER_DATA"
export const SET_LOGIN_ADMIN_USER_TOKEN = "SET_LOGIN_ADMIN_USER_TOKEN"
export const LOGOUT_ADMIN_USER = "LOGOUT_ADMIN_USER"

export const GET_STATE_DATA = "GET_STATE_DATA"
export const SET_STATE_DATA = "SET_STATE_DATA"

export const GET_DISTRICTS_DATA = "GET_DISTRICTS_DATA"
export const SET_DISTRICTS_DATA = "SET_DISTRICTS_DATA"
export const SET_SELECTED_STATE_ID = "SET_SELECTED_STATE_ID"



export const GET_USER_CONTACT_DATA = "GET_USER_CONTACT_DATA"
export const SET_USER_CONTACT_DATA = "SET_USER_CONTACT_DATA"

export const ADD_USER_REF_CONTACT = "ADD_USER_REF_CONTACT"

export const UPDATE_USER_REF_CONTACT_TO_ENQUIRY = "UPDATE_USER_REF_CONTACT_TO_ENQUIRY"
export const ADD_USER_REF_CONTACT_TO_ENQUIRY = "ADD_USER_REF_CONTACT_TO_ENQUIRY"

export const ADD_NEW_CONTACT_TO_ENQUIRY = "ADD_NEW_CONTACT_TO_ENQUIRY"

export const GET_ALL_ENQUIRY = "GET_ALL_ENQUIRY"
export const SET_ENQUIRY_DATA = "SET_ENQUIRY_DATA"


export const SEND_WHATSAPP_MESSAGE = "SEND_WHATSAPP_MESSAGE"
export const SEND_TEXT_MESSAGE = "SEND_TEXT_MESSAGE"
export const SEND_NOTIFICATION_MESSAGE = "SEND_NOTIFICATION_MESSAGE"
export const SEND_EMAIL_MESSAGE = "SEND_EMAIL_MESSAGE"


