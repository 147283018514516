import { AnyAction } from "redux-saga";
import { Statuses } from "../../components/constants";
import { ApplicationState, ILoaderState } from "../types";
import { CHANGE_LOADER } from "../actions/loaderActions";

const initialState: ILoaderState = {
    loadingState: Statuses.IDLE
}

export default function reducer(state = initialState, action: AnyAction){
    switch(action.type){
        case CHANGE_LOADER:{
            const {loadingState} = action.payload;
            return {...state, loadingState}
        }
        default:
            return state;
    }
}


