import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const loading = () => <div className="animated fadeIn pt-3 text-center">
  <div className="">
    <img src="../images/saftyfirst.png" alt="emergency call app " title='Emergency Call App ' />
  </div>
</div>;

// Containers


const EmergencyPleaseLayout = Loadable({
  loader: () => import('./containers/EmergencyPleaseLayout/EmergencyPleaseLayout'),
  loading
});

const PartnerLayout = Loadable({
  loader: () => import('./containers/PartnerLayout'),
  loading
});

const AdminLayout = Loadable({
  loader: () => import('./containers/AdminLayout'),
  loading
});

const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/ThemeComponents/Pages/Login/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/ThemeComponents/Pages/Register/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/ThemeComponents/Pages/Page404/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/ThemeComponents/Pages/Page500/Page500'),
  loading
});

const App = () => {
  const containerStyle = {
    zIndex: 1999
  };
  return (
    <Fragment>
      <ToastContainer position="top-right" autoClose={5000} style={containerStyle} />
      <Router>
        <Switch>
          <Route exact path="/login" key="Login Page" component={Login} />
          <Route exact path="/register" key="Register Page" component={Register} />
          <Route exact path="/404" key="Page 404" component={Page404} />
          <Route exact path="/500" key="Page 500" component={Page500} />
          <Route path="/partner" key="Home" component={PartnerLayout} />
          <Route path="/admin" key="Home" component={AdminLayout} />
          <Route path="/theme" key="Home" component={DefaultLayout} />
          <Route path="/" key="Home" component={EmergencyPleaseLayout} />
        </Switch>
      </Router>
    </Fragment>

  );
}

export default App;
