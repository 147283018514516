export const Statuses = {
    IDLE:'IDLE',
    PENDING:'PENDING',
    DONE:'DONE',
    ERROR:'ERROR',
    SUBMITTING:'SUBMITTING',
    SUBMITTING_ERROR:'SUBMITTING_ERROR',
    REFRESHING:'REFRESHING',
    REFRESHING_ERROR:'REFRESHING_ERROR',
    OK:'OK',
    WARN:'WARN',
}