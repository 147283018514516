// import AsyncStorage from "@react-native-community/async-storage";
// import request from "../common/api_client";
// import qs from "qs"

// import request from "common/api_clients";
import qs from "qs";
import request from "./common/api_clients";
import StorageService from "./StorageService";
// import ImageResizer from "react-native-image-resizer";
// import mime from "mime";
const partnerAppId = '/partners/distributor'
const userAppId = '/user'
const storageService = new StorageService();
// const emergencyRequest = async (formData) => {
//   // let data = qs.stringify(formData)
//   // userToken = await storageService.getStringStorage('userToken');
//   return request({
//     url: `/request/add`,
//     method: 'POST',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//       // 'Authorization': `Bearer ${userToken}`,
//     }
//   });
// }

// const updateEmergencyContactDetails = async (formData) => {
//   let data = qs.stringify(formData)
//   userToken = await AsyncStorage.getItem('userToken');

//   return request({
//     url: `/partnerAppId/update/emergencycontactdetails`,
//     method: 'PATCH',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//       'Authorization': `Bearer ${userToken}`,
//     }
//   });
// }


const getQrIdDetails = async (qrId) => {

  return request({
    url: `ecardscan/getecarddetails/${qrId}`,
    method: 'GET',
    // data:{qrid:'1031671683'},
    headers: {
      // 'Authorization' : `Bearer ${userToken}`
    }
  });
}

const newQRScan = async (qrId, formData) => {
  let data = qs.stringify(formData)

  return request({
    url: `/ecardscan/newscan/${qrId}`,
    method: 'POST',
    data,
    headers: {
      // "Content-Type": "multipart/form-data"
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      // 'Authorization': `Bearer ${userToken}`,
    }
  });
}

// const requestservicebyservicetype = async (serviceID) => {

//   userToken = await AsyncStorage.getItem('userToken');
//   return request({
//       url: `/service/requestservicebyservicetype/${serviceID}`,
//       method: 'GET',
//       headers: {
//           'Authorization': `Bearer ${userToken}`
//       }
//   });
// }

// const serviceservicebyservicetype = async (serviceID) => {

//   userToken = await AsyncStorage.getItem('userToken');
//   return request({
//       url: `/service/serviceservicebyservicetype/${serviceID}`,
//       method: 'GET',
//       headers: {
//           'Authorization': `Bearer ${userToken}`
//       }
//   });
// }

// const createOrderId = async (formData) => {
//   userToken = await AsyncStorage.getItem('userToken');
//   let data = qs.stringify(formData)
//   return request({
//       url: `/order/create/orderId`,
//       method: 'POST',
//       data,
//       headers: {
//           'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//           'Authorization': `Bearer ${userToken}`,
//       }
//   });
// }

const checkphonenumber = (phonenumber) => {
  try {
    return request({
      url: `/user/checkphonenumber/${phonenumber}`,
      method: 'GET',
      handleHeaders: 2
    });

  } catch (e) {
    return e
  }
}
const checkemail = (email) => {

  try {
    return request({
      url: `/user/checkemail/${email}`,
      method: 'GET',
      handleHeaders: 2
    });

  } catch (e) {
    return e
  }

}

const AdminloginViaPhonenumber = async (formData) => {
  let data = qs.stringify(formData)

  try {
    const loginRes = await request({
      url: `/admin/loginviaphonenumber`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });

    return loginRes;
  } catch (error) {
    return Promise.reject(error);
  }

}

const loginViaPhonenumber = async (formData) => {
  let data = qs.stringify(formData);

  try {
    const loginRes = await request({
      url: `/user/loginviaphonenumber`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes

  } catch (e) {
    return e
  }

}
const loginviaotppassword = (formData) => {
  let data = qs.stringify(formData)

  try {
    return request({
      url: `/user/loginviaotppassword`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });

  } catch (e) {
    return e
  }

}

const getPaymentKey = async (scanId) => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/onlinepayment/paymentkey`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const getTestPaymentKey = async (scanId) => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/onlinepayment/testpaymentkey`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const createOrderId = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    return request({
      url: `/order/create/orderId`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': `Bearer ${userToken}`,
      }
    });
  } catch (error) {
    return error
  }

}

const createTestOrderId = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    return request({
      url: `/order/create/testorderid`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': `Bearer ${userToken}`,
      }
    });
  } catch (error) {
    return error
  }

}

const paymentVerify = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData);

  try {
    return request({
      url: `/order/payment/verify`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': `Bearer ${userToken}`,
      }
    });
  } catch (error) {
    return error

  }

}

const testPaymentVerify = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData);

  try {
    return request({
      url: `/order/testpayment/verify`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': `Bearer ${userToken}`,
      }
    });
  } catch (error) {
    return error

  }

}

const onlinePayment = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData);

  try {
    return request({
      url: `/onlinepayment/newpayment`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': `Bearer ${userToken}`,
      }
    });
  } catch (error) {
    return error
  }

}

const getPaymenytDetails = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData);

  try {
    return request({
      url: `/onlinepayment/paymentdetails`,
      method: 'POST',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const updatedPaymentDetails = async (formData) => {
  let data = qs.stringify(formData);
  const userToken = await storageService.getStringStorage('userToken');

  return request({
    url: `/onlinepayment/updatedpayment`,
    method: 'PATCH',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'Authorization': `Bearer ${userToken}`,
    }
  });
}

const getMyPaymentDetails = async (scanId) => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/onlinepayment/mypaymentdetails`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const getUserProfile = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/user/getuserprofile`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const userRegister = async (formData) => {
  let data = qs.stringify(formData)

  try {
    const signupResponse = await request({
      url: `/user/signup`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return signupResponse;
  } catch (e) {
    return e
  }
}

const adminLogin = async (formData) => {
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/user/loginviaemail`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });

    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }

}

const getAllUsers = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/user/getallusers`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}

const getAllEcardScan = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/ecardscan/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }

}


const getAllBloodBank = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/bloodbank/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}

const getAllBloodDonar = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/blooddonor/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllSnakeFriend = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/snakefriends/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllAnimalFriend = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/animalfriends/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllEmergencyWarrior = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/emergencywarriors/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllMissingPeoples = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/admin/missingpeoples/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllState = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/state/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}
const getAllDistrict = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/district/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}

const createUserEnquiry = async (formData) => {
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/addnew`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });

    return loginRes;
  } catch (error) {
    console.log(error)
    throw new Error(error.message);
  }
}

const addNewEnquiry = async (formData, adminToken) => {
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/addnewenquiry`,
      method: 'POST',
      data,
      headers: {
        'Authorization': `Bearer ${adminToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });

    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
}




const getAllEnquiry = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/userenquiry/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}


const removeDataFromUserContact = async (contact_number) => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    const loginRes = await request({
      url: `/usercontacts/delete/${contact_number}`,
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    });

    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
}


const sendWhatsAPPMessage = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/sendwhatsappmessage`,
      method: 'PUT',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
}


const sendTextMessage = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/sendtextmessage`,
      method: 'PUT',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
}


const sendNotificationMessage = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/sendnotificationmessage`,
      method: 'PUT',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
}

const sendEmailMessage = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/sendemailmessage`,
      method: 'PUT',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
} 

const getUserContactsData = async () => {
  const userToken = await storageService.getStringStorage('userToken');
  try {
    return request({
      url: `/usercontacts/all`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });

  } catch (e) {
    return e
  }
}

const updateUserEnquiry = async (formData) => {
  const userToken = await storageService.getStringStorage('userToken');
  let data = qs.stringify(formData)
  try {
    const loginRes = await request({
      url: `/userenquiry/updateenquiry`,
      method: 'PUT',
      data,
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    return loginRes;
  } catch (e) {
    console.log(e)
    return e
  }
} 


const AdminServices = {
  updateUserEnquiry,
  AdminloginViaPhonenumber,
  getUserContactsData,
  sendEmailMessage,
  sendNotificationMessage,
  sendTextMessage,
  sendWhatsAPPMessage,
  removeDataFromUserContact,
  getAllEnquiry,
  createUserEnquiry,
  addNewEnquiry,
  getAllDistrict,
  getAllState,
  adminLogin,
  getAllEcardScan,
  getAllUsers,
  getAllBloodBank,
  getAllBloodDonar,
  getAllSnakeFriend,
  getAllAnimalFriend,
  getAllEmergencyWarrior,
  getAllMissingPeoples,

  getUserProfile,
  getPaymentKey,
  getTestPaymentKey,
  createOrderId,

  paymentVerify,
  onlinePayment,

  createTestOrderId,
  testPaymentVerify,

  getPaymenytDetails,
  updatedPaymentDetails,
  getMyPaymentDetails,

  userRegister,

  getQrIdDetails,
  newQRScan,
  checkphonenumber,
  checkemail,
  // loginViaEmail,
  loginViaPhonenumber,
  loginviaotppassword
  // requestservicebyservicetype,
  //   serviceservicebyservicetype,
};

export default AdminServices;