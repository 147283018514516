import { takeEvery, put, takeLatest } from 'redux-saga/effects'
import { ADD_NEW_CONTACT_TO_ENQUIRY, ADD_USER_REF_CONTACT, ADD_USER_REF_CONTACT_TO_ENQUIRY, GET_ALL_ENQUIRY, GET_DISTRICTS_DATA, GET_STATE_DATA, GET_USER_CONTACT_DATA, GET_USER_DATA, LOGIN_ADMIN_USER, PRODUCT_LIST, SEARCH_PRODUCT, SEND_EMAIL_MESSAGE, SEND_NOTIFICATION_MESSAGE, SEND_TEXT_MESSAGE, SEND_WHATSAPP_MESSAGE, SET_DISTRICTS_DATA, SET_ENQUIRY_DATA, SET_LOGIN_ADMIN_USER_DATA, SET_LOGIN_ADMIN_USER_TOKEN, SET_PRODUCT_LIST, SET_STATE_DATA, SET_USER_CONTACT_DATA, SET_USER_DATA, UPDATE_LOGIN_ADMIN_USER, UPDATE_USER_REF_CONTACT_TO_ENQUIRY } from './constant';
import AdminServices from '../services/AdminServices';
import { Statuses } from '../components/constants';
import { CHANGE_LOADER, setLoading } from './actions/loaderActions';
import { setToster } from './actions/tosterActions';
import StorageService from '../services/StorageService';

const storageService = new StorageService();
function* getProducts() {
    try {
        yield put(setLoading(Statuses.PENDING))
        let data = yield fetch('http://localhost:3500/products');
        data = yield data.json();
        console.warn("action is called", data)
        yield put({ type: SET_PRODUCT_LIST, data });

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

function* searchProducts(data) {
    try {
        yield put(setLoading(Statuses.PENDING));
        let result = yield fetch(`http://localhost:3000/products?q=${data.query}`);
        result = yield result.json();
        console.warn("action is called", result)
        yield put({ type: SET_PRODUCT_LIST, data: result })

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

function* getALLUserData() {
    try {
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.getAllUsers();
        console.log(data, 'data check@@@');
        yield put({ type: SET_USER_DATA, data: data.Users })
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

function* getALLState() {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.getAllState();
        yield put({ type: SET_STATE_DATA, data: data.states });
        yield put({ type: GET_DISTRICTS_DATA });

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

function* getALLDistrict() {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.getAllDistrict();
        yield put({ type: SET_DISTRICTS_DATA, data: data.districts })
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

function* getALLEnquiry() {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.getAllEnquiry();
        yield put({ type: SET_ENQUIRY_DATA, data: data.userEnquiry })
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        yield put(setLoading(Statuses.ERROR))

    }
}
export function* addUserReferanceContactsData(action) {
    try {
        console.log(action.payload);

        yield put(setLoading(Statuses.PENDING))
        // const data = yield AdminServices.createUserEnquiry(action.payload);
        // yield put({type: SET_DISTRICTS_DATA, data:data.districts})
        // yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}

export function* addUserReferanceContactsToEnquiryData(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        const createUserEnquiry = yield AdminServices.createUserEnquiry(action.payload);
        const { status_code } = createUserEnquiry
        // if (status_code === 200) {
        //     yield AdminServices.removeDataFromUserContact(action.payload.contact_number);
        // }
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        yield put(setLoading(Statuses.ERROR))
    }
}

export function* addNewEnquiryData(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        yield AdminServices.addNewEnquiry(action.payload, action.adminToken);
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))
    }
}

export function* sendWhatsAPPMessage(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        const createUserEnquiry = yield AdminServices.sendWhatsAPPMessage(action.payload);
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))
    }
}

export function* sendTextMessage(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        const createUserEnquiry = yield AdminServices.sendTextMessage(action.payload);
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))
    }
}

export function* sendNotificationMessage(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        const createUserEnquiry = yield AdminServices.sendNotificationMessage(action.payload);
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))
    }
}

export function* sendEmailMessage(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        const createUserEnquiry = yield AdminServices.sendEmailMessage(action.payload);
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))
    }
}

function* getUserContactsData() {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.getUserContactsData();
        console.log(data, 'data check@@@');
        yield put({ type: SET_USER_CONTACT_DATA, data: data.contacts })
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);

    }
}

function* loginAdminUser(action) {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.AdminloginViaPhonenumber(action.payload);
        console.log(data, 'data check@@@');
        storageService.setStringStorage(SET_LOGIN_ADMIN_USER_TOKEN, data.token);
        storageService.setStringStorage(SET_LOGIN_ADMIN_USER_DATA, JSON.stringify(data.user));
        yield put({ type: SET_LOGIN_ADMIN_USER_DATA, data: data.user })
        yield put({ type: SET_LOGIN_ADMIN_USER_TOKEN, data: data.token })
        yield put(setLoading(Statuses.DONE))

    } catch (error: any) {
        yield put(setToster(Statuses.ERROR, error.message))
        yield put(setLoading(Statuses.ERROR))
    }
}


function* updateUserEnquiry(action) {
    try {
        // yield put({type: CHANGE_LOADER, loadingState: Statuses.PENDING})
        yield put(setLoading(Statuses.PENDING))
        const data = yield AdminServices.updateUserEnquiry(action.payload);
        console.log(data, 'data check@@@');
        yield put({ type: GET_ALL_ENQUIRY });
        yield put(setLoading(Statuses.DONE))

    } catch (error) {
        console.log(error);
        yield put(setLoading(Statuses.ERROR))

    }
}


function* loginDataFromLocal(action) {
    try {
        yield put(setLoading(Statuses.PENDING))
        yield put({ type: SET_LOGIN_ADMIN_USER_DATA, data: action.payload.userData })
        yield put({ type: SET_LOGIN_ADMIN_USER_TOKEN, data: action.payload.token })
        yield put(setLoading(Statuses.DONE))

    } catch (error: any) {
        yield put(setToster(Statuses.ERROR, error.message))
        yield put(setLoading(Statuses.ERROR))

    }
}

function* rootSaga() {


    yield takeEvery(UPDATE_LOGIN_ADMIN_USER, loginDataFromLocal)

    yield takeEvery(UPDATE_USER_REF_CONTACT_TO_ENQUIRY, updateUserEnquiry)

    yield takeLatest(LOGIN_ADMIN_USER, loginAdminUser)
    yield takeLatest(GET_USER_CONTACT_DATA, getUserContactsData)
    yield takeEvery(GET_USER_DATA, getALLUserData)
    yield takeEvery(PRODUCT_LIST, getProducts)
    yield takeEvery(SEARCH_PRODUCT, searchProducts)
    yield takeEvery(GET_STATE_DATA, getALLState)
    yield takeEvery(GET_DISTRICTS_DATA, getALLDistrict)
    yield takeEvery(ADD_USER_REF_CONTACT, addUserReferanceContactsData)
    yield takeEvery(ADD_USER_REF_CONTACT_TO_ENQUIRY, addUserReferanceContactsToEnquiryData)
    yield takeEvery(ADD_NEW_CONTACT_TO_ENQUIRY, addNewEnquiryData)
    yield takeEvery(GET_ALL_ENQUIRY, getALLEnquiry)
    yield takeEvery(SEND_WHATSAPP_MESSAGE, sendWhatsAPPMessage)
    yield takeEvery(SEND_TEXT_MESSAGE, sendTextMessage)
    yield takeEvery(SEND_NOTIFICATION_MESSAGE, sendNotificationMessage)
    yield takeEvery(SEND_EMAIL_MESSAGE, sendEmailMessage)




}

export default rootSaga;