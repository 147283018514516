import { AnyAction } from "redux-saga";
import { Statuses } from "../../components/constants";
import { ApplicationState, ILoaderState, IUserDataState } from "../types";
import { CHANGE_LOADER } from "../actions/loaderActions";
import { SET_USER_DATA } from "../constant";

const initialState: IUserDataState = {
  userDataList: [],
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    // case GET_USER_DATA: {
    //   // const {loadingState} = action.payload;
    //   return { ...state, userDataList: action.data };
    // }
    case SET_USER_DATA: {
      // const {loadingState} = action.payload;
      return { ...state, userDataList: action.data };
    }
   
    default:
      return state;
  }
}
