import localforage from 'localforage';
export default class StorageService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  getStringStorage(key){
    return localforage.getItem(key);
  }

  getBooleanStorage(key) {
    const value = localforage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  getNumberStorage(key)  {
    const value = localforage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

 async getObjectStorage(key) {
    const value = await localforage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  setStringStorage(key, value){
    localforage.setItem(key, value);
  }

  setBooleanStorage(key, value){
    localforage.setItem(key, value.toString());
  }

  setNumberStorage(key, value){
    localforage.setItem(key, value.toString());
  }

  setObjectStorage(key, value){
    localforage.setItem(key, JSON.stringify(value));
  }

  removeStorage(key){
    localforage.removeItem(key);
  }
}

