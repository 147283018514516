import { AnyAction } from "redux-saga";
import { Statuses } from "../../components/constants";
import { ApplicationState, IAdminDataState, ILoaderState, IUserDataState } from "../types";
import { CHANGE_LOADER } from "../actions/loaderActions";
import { LOGOUT_ADMIN_USER, SET_DISTRICTS_DATA, SET_ENQUIRY_DATA, SET_LOGIN_ADMIN_USER_DATA, SET_LOGIN_ADMIN_USER_TOKEN, SET_SELECTED_STATE_ID, SET_STATE_DATA, SET_USER_CONTACT_DATA } from "../constant";
import StorageService from "../../services/StorageService";

const initialState: IAdminDataState = {
  stateList: [],
  districtList: [],
  enquiryData: [],
  userContactDataList: [],
  selectedStateID: '',
  selectedDistrictID: '',
  adminUserLoginData: {},
  adminUserLoginToken: ''
};
const storageService = new StorageService();

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_STATE_DATA: {
      return { ...state, stateList: action.data };
    }
    case SET_DISTRICTS_DATA: {
      return { ...state, districtList: action.data };
    }
    case SET_SELECTED_STATE_ID: {
      return { ...state, selectedStateID: action.stateID };
    }
    case SET_ENQUIRY_DATA: {
      return { ...state, enquiryData: action.data };
    }
    case SET_USER_CONTACT_DATA: {
      // const {loadingState} = action.payload;
      return { ...state, userContactDataList: action.data };
    }
    case SET_LOGIN_ADMIN_USER_DATA: {
      // const {loadingState} = action.payload;
      return { ...state, adminUserLoginData: action.data };
    }
    case SET_LOGIN_ADMIN_USER_TOKEN: {
      // const {loadingState} = action.payload;
      return { ...state, adminUserLoginToken: action.data };
    }
    case LOGOUT_ADMIN_USER: {
      storageService.removeStorage(SET_LOGIN_ADMIN_USER_TOKEN);
      storageService.removeStorage(SET_LOGIN_ADMIN_USER_DATA);
      return { ...state, adminUserLoginToken: '', adminUserLoginData: {} };
    }
    default:
      return state;
  }
}
