import { AnyAction } from "redux-saga";
import { Statuses } from "../../components/constants";
import { IToasterState } from "../types";
import { CHANGE_TOASTER } from "../actions/tosterActions";

const initialState: IToasterState = {
    toasterState: Statuses.IDLE,
    toasterMessage:''
}

export default function reducer(state = initialState, action: AnyAction){
    switch(action.type){
        case CHANGE_TOASTER:{
            const {toasterState, toasterMessage} = action.payload;
            return {...state, toasterState, toasterMessage}
        }
        default:
            return state;
    }
}


