import { combineReducers } from 'redux'
import { cartData } from './cartReducer'
import { productData } from './productReducer'
import { loaderReducers, userDataReducers, adminDataReducers, toasterReducers } from '.'
export default combineReducers({
    loader: loaderReducers,
    toster: toasterReducers,
    userData: userDataReducers,
    adminData: adminDataReducers,
    cartData,
    productData
})